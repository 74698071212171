import { memo } from 'react';
import * as style from './PasswordStrengthIndicator.module.less';

export interface Props {
    password?: string;
    minChar?: number;
}

export const minimumPasswordLength = 12;

const verdicts = [
    {
        text: 'For kort',
        color: '#ccc',
        value: 20,
        maxScore: -1,
    },
    {
        text: 'Veldig svakt',
        color: '#f00',
        value: 30,
        maxScore: 10,
    },
    {
        text: 'Svakt',
        color: '#c06',
        value: 40,
        maxScore: 15,
    },
    {
        text: 'Medium',
        color: '#f60',
        value: 60,
        maxScore: 30,
    },
    {
        text: 'Sterkt',
        color: '#3c0',
        value: 80,
        maxScore: 40,
    },
    {
        text: 'Veldig sterkt',
        color: '#3f0',
        value: 100,
        maxScore: Infinity,
    },
];

function calculateScore(password: string, minChar: number) {
    let score = 0;

    if (password.length < minChar) {
        score = score - 100;
    } else if (password.length > minChar + 1 && password.length < minChar + 3) {
        score = score + 6;
    } else if (password.length > minChar + 2 && password.length < minChar + 4) {
        score = score + 12;
    } else if (password.length > minChar * 2) {
        score = score + 18;
    }

    if (password.match(/[a-z]/)) {
        score = score + 1;
    }
    if (password.match(/[A-Z]/)) {
        score = score + 5;
    }
    if (password.match(/\d+/)) {
        score = score + 5;
    }
    if (password.match(/(.*[0-9].*[0-9].*[0-9])/)) {
        score = score + 5;
    }
    if (password.match(/.[!,@,#,$,%,^,&,*,?,_,~]/)) {
        score = score + 5;
    }
    if (password.match(/(.*[!,@,#,$,%,^,&,*,?,_,~].*[!,@,#,$,%,^,&,*,?,_,~])/)) {
        score = score + 5;
    }
    if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
        score = score + 2;
    }
    if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) {
        score = score + 2;
    }
    if (password.match(/([a-zA-Z0-9].*[!,@,#,$,%,^,&,*,?,_,~])|([!,@,#,$,%,^,&,*,?,_,~].*[a-zA-Z0-9])/)) {
        score = score + 2;
    }

    return score;
}

export default memo(function PasswordStrengthIndicator({ password, minChar = minimumPasswordLength }: Props) {
    if (!password) {
        return null;
    }

    const score = calculateScore(password, minChar);
    const verdict = verdicts.find((v) => score <= v.maxScore);

    if (!verdict) {
        return null;
    }

    return (
        <div className={style.progress}>
            <div
                className={style.progressBar}
                role="progressbar"
                aria-valuenow={verdict.value}
                aria-valuemin={0}
                aria-valuemax={100}
                style={{
                    '--progress-value': verdict.value,
                    '--progress-color': verdict.color,
                }}
            >
                <span>{verdict.text}</span>
            </div>
        </div>
    );
});
